import { ButtonBase, ButtonBaseProps } from "@mui/material";
import { styled } from "@mui/system";
import { styled as muiStyled } from "@mui/material/styles";
export const Container = styled("div")`
  display: flex;
  margin: 24px 16px;
  flex-direction: column;
  align-items: center;
  & > main {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1007px;
    width: 100%;
    justify-content: center;
    margin-bottom: 36px;
    ${(props) => props.theme.breakpoints.up("sm")} {
      justify-content: space-between;
    }
  }
  & > aside {
    width: 100%;
    max-width: 1007px;
  }
  & > main > p {
    max-width: 504px;
    margin-bottom: 32px;
  }
  & > main img {
    max-height: 54px;
  }
`;

export const QuizTitle = styled("h1")`
  font-weight: 700;
  font-size: 32px;
  margin: 32px 0 16px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    text-align: left;
    font-size: 35px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    font-size: 40px;
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    font-size: 50px;
  }
`;

export const RedeemPoints = muiStyled(ButtonBase)<ButtonBaseProps>(
  ({ theme }) => ({
    display: "inline-block",
    padding: "0.9rem 1.8rem",
    margin: "3rem 0",
    fontSize: "1rem",
    fontWeight: 700,
    border: " 4px solid #ffca50",
    color: "white",
    backgroundColor: "transparent",
    fontFamily: "inherit",
    overflow: "hidden",
    borderRadius: "8px",
    transition: "all .4s",
    width: 200,
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      backgroundColor: " #ffca50",
      transform: "translateX(-100%)",
      transition: "all .3s",
      zIndex: -1,
    },
    "&:hover::before": {
      transform: "translateX(0)",
    },
    "&:hover": {
      color: "black",
    },
  })
);

export const ButtonContainer = styled("div")`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    flex-wrap: wrap;
    & > div {
      margin-right: 0.5rem;
    }
  }
  justify-content: space-between;
  min-width: 75%;
  align-items: center;
`;

export const CloseLightboxButton = styled("button")`
  border-radius: 10px;
  background: transparent;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.palette.primary.light};;
  width: 100%;
  padding: 16px 0;
  color: ${(props) => props.theme.palette.primary.light};;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
`;

export const SuccessImage = styled("img")`
  margin: 0 auto;
  display: flex;
`;
