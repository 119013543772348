import { IS_FC } from "./../constants/index";
import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: IS_FC ? "#FFCD00" : "rgba(0, 167, 237, 1)",
      light: IS_FC ? "#fcca50" : "#69D6FF",
      dark: IS_FC
        ? "1px solid rgba(252, 202, 80, 0.5);"
        : "1px solid rgba(0, 167, 237, 1);",
    },
    background: {
      default: "#1E1E1E",
    },
  },
  typography: {
    h2: {
      fontSize: "2.50rem",
      fontWeight: 700,
      userSelect: "none",
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 700,
      userSelect: "none",
    },
  },
});
