import { styled } from "@mui/system";

export const HubspotContainer = styled("div")`
  label {
    color: ${(props) => props.theme.palette.primary.light};
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
    display: inline-block;
    margin-top: 16px;
  }

  .custom-hubspot-form > .hs-email > .input {
    display: flex;
  }

  .custom-hubspot-form input[type="email"],
  .custom-hubspot-form input[type="text"] {
    background: #303030;
    width: 100%;
    border: 1px solid rgba(112, 112, 112, 0.3);
    border-radius: 10px;
    padding: 20px 5px 20px 16px;
    color: #fff;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  select {
    background: #303030;
    width: 100%;
    border: 1px solid rgba(112, 112, 112, 0.3);
    border-radius: 10px;
    padding: 20px 5px 20px 16px;
    color: #fff;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .custom-hubspot-form input[type="email"]:focus,
  .custom-hubspot-form input[type="text"]:focus {
    outline: none;
    border: ${(props) => `${props.theme.palette.primary.dark}`};
  }

  select:focus {
    outline: none;
    border: ${(props) => `${props.theme.palette.primary.dark}`};
  }

  .custom-errors {
    margin: 0;
    padding: 0;
    display: none;
  }

  .custom-errors li label {
    color: #ff4848;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .custom-errors li,
  .hs-form-booleancheckbox {
    list-style-type: none;
  }

  .hs-form-booleancheckbox span {
    color: #fff;

    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .hs-fieldtype-booleancheckbox > .input > ul {
    margin: 0;
    padding: 23px 0;
    text-align: center;
  }

  .hs-form-booleancheckbox li {
    list-style-type: none;
  }

  .custom-submit {
    border-radius: 10px;
    background: ${(props) => props.theme.palette.primary.light};
    width: 100%;
    border: none;
    padding: 16px 0;
    color: #000;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 32px;
  }

  .submitted-message {
    text-align: center;
    color: #fff;
    margin-bottom: 100px;
  }
`;
