import { LinearProgress, LinearProgressProps } from "@mui/material";
import { styled } from "@mui/system";
import { styled as muiStyled } from "@mui/material/styles";

export const CourseProgressContainer = styled("div")`
  margin-bottom: 52px;
`;

export const StyledProgress = muiStyled(LinearProgress)<LinearProgressProps>(
  ({ theme }) => ({
    borderRadius: "8px",
    minHeight: "8px",
    backgroundColor: "#252525",
    [theme.breakpoints.up("md")]: {
      minHeight: "12px",
    },
  })
);

export const ProgressIcon = styled("div")`
  border: ${(props) => `${props.theme.palette.primary.dark}`};
  display: flex;
  position: absolute;
  justify-self: flex-start;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 32px;
  width: 32px;
  margin-top: -22px;
  & > img {
    height: 32px;
    width: 32px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 52px;
    width: 52px;
    margin-top: -32px;
    & > img {
      height: 52px;
      width: 52px;
    }
  }
`;

export const Infos = styled("div")`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 8px;
`;

export const Answers = styled("div")`
  margin-top: 36px;
  & > h5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    margin-bottom: 16px;
  }
  & > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
  }
  & p {
    margin: 0;
  }
  & img {
    margin-right: 8px;
  }
  & span {
    color: #63d260;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
`;

export const Question = styled("p")`
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px !important;
`;

export const RightAnswer = styled("p")`
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
