import { styled } from "@mui/material/styles";
import { FormLabel } from "@mui/material";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";

export const QuestionContainer = styled("div")`
  width: 848px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledFormLabel = styled(FormLabel)`
  color: ${(props) => props.theme.palette.primary.light};
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  position: relative;
  justify-content: center;

  ${(props) => props.theme.breakpoints.up("sm")} {
    justify-content: flex-start;
  }
`;

export const QuestionNumber = styled("span")`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.theme.palette.primary.light};;
  margin-right: 20px;
  font-family: "Source Sans Pro", sans-serif;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 22px;
    line-height: 28px;
    margin-right: 24px;
  }
`;

export const QuestionTitle = styled("p")`
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  display: inline-block;
  font-family: "Source Sans Pro", sans-serif;
`;

export const StyledForm = styled("form")`
  width: 100%;
`;

export const StyledFormControlLabel = styled(
  FormControlLabel
)<FormControlLabelProps>(({ theme, checked, }) => ({
  border: checked
    ? theme.palette.primary.dark
    : "1px solid transparent",
  background: "#202020",
  borderRadius: "10px",
  padding: "7px",
  margin: "0 0 16px 0",
  columnGap: "12px",
  [theme.breakpoints.up("md")]: {
    columnGap: "24px",
  },
}));

export const StyledIcon = styled("span")`
  border: 1px solid rgba(75, 75, 75, 0.5);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: relative;

  ${(props) => props.theme.breakpoints.up("md")} {
    height: 32px;
    width: 32px;
  }
`;

export const StyledCheckedIcon = styled("span")`
  border: 1px solid rgba(75, 75, 75, 0.5);
  border-radius: 50%;
  height: 20px;
  width: 20px;

  &:before {
    content: "";
    height: 12px;
    width: 12px;
    background-color: ${(props) => props.theme.palette.primary.light};;
    display: block;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    ${(props) => props.theme.breakpoints.up("md")} {
      height: 20px;
      width: 20px;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 32px;
    width: 32px;
  }
`;

export const StyledLabel = styled("span")`
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
`;

export const CustomButton = styled("button")`
  width: 300px;
  padding: 24px 0;
  background: ${(props) => props.theme.palette.primary.light};;
  border-radius: 10px;
  border: none;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  font-family: Source Sans Pro, sans-serif;
  margin-top: 16px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  cursor: pointer;
  color: #000;

  &:disabled {
    background: #363636;
    color: #000;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 416px;
    padding: 28px 0;
    margin-right: unset;
    margin-left: unset;
  }

  ${(props) => props.theme.breakpoints.up("xl")} {
    width: 416px;
    padding: 32px 0;
  }
`;

export const ErrorWarning = styled("span")`
  width: 35px;
  height: 35px;
  background: #301616;
  border: 1px solid rgba(255, 0, 0, 0.5);
  border-radius: 50%;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);

  ${(props) => props.theme.breakpoints.up("sm")} {
    width: 48px;
    height: 48px;
    left: -60px;
  }
`;

export const ErrorMessage = styled("div")`
  background: #301616;
  border: 1px solid rgba(255, 0, 0, 0.5);
  border-radius: 8px;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ff0000;
  width: fit-content;

  ${(props) => props.theme.breakpoints.up("sm")} {
    padding: 46px 25px;
    width: 100%;
  }
`;
